:root {
  --spacing-xx-small:  0.125rem;
  --spacing-x-small:   0.25rem;
  --spacing-small:     0.5rem;
  --spacing-medium:    1rem;
  --spacing-large:     2rem;
  --spacing-x-large:   3rem;
  --spacing-xx-large:  4rem;
  --spacing-xxx-large: 8rem;

  --spacing-section:       var(--spacing-xx-large);
  --spacing-section-large: var(--spacing-xxx-large);
}
