.button {
	border-radius: calc(var(--border-radius-medium) * 2);
	box-shadow: var(--box-shadow-medium);
	display: inline-flex;
	font-size: var(--font-size-small);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-small);
	padding: var(--spacing-medium) var(--spacing-large);
	text-decoration: none;

	&--primary {
		background-color: var(--color-text-primary);
		color: var(--color-text-inverse);

		&:hover {
			background-color: var(--color-text-secondary);
			color: var(--color-text-inverse);
		}
	}

	&--secondary {
		border: 1px solid var(--color-border);
		color: var(--color-text-primary);
	}

	&--small {
		box-shadow: var(--box-shadow-small);
		padding: var(--spacing-small) var(--spacing-medium);
	}

	& + .button {
		margin-left: var(--spacing-small);
	}
}
