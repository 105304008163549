.theme-switcher {
  align-items: center;;
  display: none;
  gap: var(--spacing-xx-small);

  @media screen and (min-width: 375px) {
    display: flex;
  }
}

.theme-switcher__button {
  all: unset;

  align-items: center;
  border-radius: var(--border-radius-circle);
  color: var(--color-text-secondary);
  cursor: pointer;
  display: inline-flex;
  height: 1.5rem;
  justify-content: center;
  padding: var(--spacing-x-small);
  position: relative;
  transition: var(--transition-medium);
  width: 1.5rem;

  &:hover,
  &:focus {
    background-color: var(--color-background-secondary);
    color: var(--color-text-primary);
  }
}

.theme-switcher__label {
  top: calc(100% + var(--spacing-small));
  color: var(--color-text-secondary);
  font-size: var(--font-size-x-small);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: var(--transition-medium);
  white-space: nowrap;

  @media (hover: hover) {
    .theme-switcher__button:hover & {
      opacity: 1;
    }
  }
}
