body {
  display: flex;
  flex-direction: column;
  padding-inline-start: var(--spacing-large);
  padding-inline-end: var(--spacing-large);

  @media screen and (min-width: 769px) {
    margin-inline: auto;
    max-width: 50rem;
    padding-inline-start: var(--spacing-xx-large);
    padding-inline-end: var(--spacing-xx-large);
  }
}

main {
  flex: 1;
}
