.currently {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  padding-block-end: var(--spacing-section);
}

.currently__heading {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-small);
}

.currently__list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  list-style: none;
  margin: 0;
}

.currently__list-item {
  align-items: center;
  counter-increment: li;
  display: flex;
  font-size: var(--font-size-medium);
  gap: var(--spacing-medium);
  line-height: var(--line-height-medium);

  &::before {
    --counter-size: 2rem;

    align-items: center;
    aspect-ratio: 1;
    border-radius: var(--counter-size);
    border: 1px solid var(--color-border);
    color: var(--color-text-secondary);
    content: "0" counter(li);
    display: flex;
    flex-shrink: 0;
    font-size: var(--font-size-x-small);
    height: var(--counter-size);
    justify-content: center;
    width: var(--counter-size);
  }
}
