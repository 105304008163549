.footer {
  align-items: center;
  border-top: 1px solid var(--color-border);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-large);
  justify-content: space-between;
  margin-block-start: var(--spacing-section-large);
  padding-block: var(--spacing-large);
}

.body--article .footer {
  margin-block-start: 0;
}

.footer__links {
  display: flex;
  gap: var(--spacing-medium);

  @media screen and (min-width: 769px) {
    gap: var(--spacing-large);
  }
}

.footer__link {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
}
