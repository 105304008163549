.home-feed {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  padding-block-start: var(--spacing-section);
}

.home-feed__heading {
  font-size: var(--font-size-large);
  line-height: var(--line-height-small);
}
