.article-column-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xx-large);
}

.article {
  padding-block-end: var(--spacing-section);

  // Same default width for everything
  & > * {
    max-width: 40rem;

    &:first-child {
      margin-block-start: 0;
    }
  }

  // Spacing between everything
  & > * + * {
    margin-block-start: var(--spacing-large);
  }

  // More spacing before headings...
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: var(--spacing-xx-large);

    &:first-child {
      margin-block-start: 0;
    }
  }

  // ...unless those headings directly follow other headings
  h2 + h3,
  h3 + h4,
  h4 + h5 {
    margin-block-start: var(--spacing-large);
  }

  // Less spacing when a list follows a paragraph
  p + ul,
  p + ol {
    margin-block-start: var(--spacing-medium);
  }

  li:not(:last-of-type) {
    margin-block-end: var(--spacing-medium);
  }

  script + * {
    margin-block-start: 0;
  }
}

// Tighter spacing for notes/tweets
.article-list__note .article-list__excerpt {
  // Spacing between everything
  > * + * {
    margin-block-start: var(--spacing-small);
  }

  li:not(:last-of-type) {
    margin-block-end: var(--spacing-small);
  }
}

// Shared styles
.article,
.article-list__note .article-list__excerpt {
  // Use the same default font size and line-height for all elements
  & > *:not(h1, h2, h3, h4, h5) {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-large);
  }

  ul,
  ol {
    padding-inline-start: var(--spacing-large);
  }

  // Element styles begin here, in alphabetical order

  blockquote {
    background-color: var(--color-background-secondary);
    border: 1px solid var(--color-border);
    border-radius:
      var(--border-radius-medium)
      var(--border-radius-medium)
      var(--border-radius-medium)
      0;
    display: block;
    font-size: var(--font-size-medium);
    margin-block-end: 0;
    margin-inline: 0;
    padding:
      1.5rem
      var(--spacing-large);
    // Prevent blockquote from going full width when text isn't that wide

    &:first-child {
      margin-block-start: 0;
    }

    & > * + * {
      margin-block-start: var(--spacing-medium);
    }

    cite,
    p {
      display: block;
    }

    cite a {
      color: inherit;
    }
  }

  cite {
    color: var(--color-text-secondary);
    font-style: normal;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    margin-block-start: var(--spacing-medium);

    &:before {
      content: "\0020 \2015 \0020";
    }
  }

  figure {
    margin-block-start: var(--spacing-xx-large);
    margin-inline: 0;
    padding: 0;

    img {
      max-width: 100%;
    }

    & > img + img {
      margin-block-start: var(--spacing-medium);
    }

    & + * {
      margin-block-start: var(--spacing-xx-large);
    }
  }

  figcaption {
    color: var(--color-text-secondary);
    font-size: var(--font-size-small);
    line-height: var(--line-height-medium);
    margin-block-start: var(--spacing-small);

    a {
      color: inherit;
    }
  }

  iframe,
  img {
    border-radius: var(--border-radius-medium);
  }

  hr {
    background-color: var(--color-border);
    border: none;
    height: 1px;
    margin-block: 4rem;
    margin-inline-start: 0;
  }

  kbd {
    --kbd-color-background: var(--color-background-primary);
    --kbd-color-border:     #{color(grey, 80)};
    --kbd-color-text:       var(--color-text-primary);

    .theme-light & {
      --kbd-color-background: var(--color-background-primary);
      --kbd-color-border:     #{color(grey, 80)};
      --kbd-color-text:       var(--color-text-primary);
    }

    .theme-dark & {
      --kbd-color-background: #{color(grey, 55)};
      --kbd-color-border:     #{color(grey, 25)};
      --kbd-color-text:       #{color(grey, 14)};
    }

    @media (prefers-color-scheme: dark) {
      --kbd-color-background: #{color(grey, 55)};
      --kbd-color-border:     #{color(grey, 25)};
      --kbd-color-text:       #{color(grey, 14)};
    }

    background-color: var(--kbd-color-background);
    border-radius: 0.25rem;
    border: 1px solid var(--kbd-color-border);
    box-shadow: 0 2px 0 1px var(--kbd-color-border);
    color: var(--kbd-color-text);
    cursor: default;
    display: inline-block;
    font-family: var(--font-family-sans-serif);
    font-size: 0.875em;
    line-height: 1;
    min-width: 0.75rem;
    padding: 2px 5px;
    position: relative;
    text-align: center;
    top: -1px;

    &:hover {
      box-shadow: 0 1px 0 0.5px var(--kbd-color-border);
      top: 1px;
    }
  }

  .film-strip,
  .full-width,
  .landscape {
    max-width: 100%;
  }

  .pullquote {
    color: var(--color-text-secondary);
    font-size: var(--font-size-large);
  }

  // Fixes some embedded tweet BS
  .twitter-tweet {
    width: 100% !important;
  }

  .video {
    aspect-ratio: 16 / 9;
    border-radius: var(--border-radius-medium);
    max-width: 40rem;

    iframe,
    object,
    embed {
      width: 100%;
      height: 100%;
    }
  }

  .highlighter-rouge {
    max-width: 100%;
    min-width: 50%;
    width: max-content;
  }
}

// Copy buttons
.header-anchor {
  position: relative;
}

.header-anchor__copy-button {
  color: var(--color-text-secondary);
  font-weight: var(--font-weight-normal);
  inset-inline-start: -1.25em;
  margin-inline-start: var(--spacing-small);
  opacity: 0;
  position: absolute;
  text-decoration: none;
  transition: var(--transition-short);

  &:hover {
    opacity: 1;
  }
}
