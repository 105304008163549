.metadata {
	align-items: center;
	color: var(--color-text-secondary);
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	font-size: var(--font-size-small);
	gap: var(--spacing-small);

  & > a {
    color: inherit;
    text-decoration: none;
  }
}
