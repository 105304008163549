.home-list {
  margin-block-start: var(--spacing-section-large);
}

.home-list__title {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-small);
}

.home-list__grid {
  display: grid;
  gap: var(--spacing-large);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  list-style: none;
  margin-block-start: var(--spacing-large);
}

.home-list__item a {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-small);
  line-height: var(--line-height-large);
  text-decoration: none;
}

.home-list__year {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}
