.chart {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-x-small);
  opacity: 0.5;
}

.chart__month {
  aspect-ratio: 1;
  background-color: var(--color-text-primary);
  border-radius: 1px;
  height: 0.25rem;
  width: 0.25rem;
}
