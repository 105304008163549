.header {
	align-items: center;
	display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
  margin-top: var(--spacing-large);

  @media screen and (min-width: 600px) {
    gap: var(--spacing-large);
  }
}

.header__brand {
	align-items: center;
	display: flex;
	gap: 0.75rem;
  margin-inline-end: auto;
  text-decoration: none;
}

.out-of-step {
  height: 18px;
  width: 24px;
}

.header__title {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
}

.header__nav {
  align-items: center;
  display: flex;
  gap: var(--spacing-medium);

  @media screen and (min-width: 769px) {
    gap: var(--spacing-large);
  }
}

.header__nav-link {
  font-size: var(--font-size-small);
}
