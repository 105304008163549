.recording-list {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xx-large);
	list-style-type: none;
	margin: 0;
	padding-bottom: var(--spacing-section);
}

.recording-list__recording {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-medium);
	max-width: 75ch;
}

.recording-list__audio {
	border-radius: var(--border-radius-medium);
	width: 100%;
}

.recording-list__metadata {
	color: var(--color-text-secondary);
	font-size: var(--font-size-small);
}

.recording-list__text {
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: var(--font-size-medium);
	gap: var(--spacing-small);
	line-height: var(--line-height-medium);
	max-width: 75ch;
	text-decoration: none;

	p + p {
		margin-block-start: var(--spacing-small);
	}
}

.recording-list__expand-button {
	color: var(--color-text-secondary);
	font-size: var(--font-size-small);
	// Fixes a bug in Safari where `text-decoration` with offset
	// is clipped by bounding box of tighter `line-height`
	line-height: var(--line-height-large);
	text-decoration: underline;
	text-decoration-color: var(--color-text-secondary);
	text-decoration-thickness: 1px;
	text-underline-offset: 3px;
	transition: var(--transition-length-short);

	&:hover,
	&:focus {
		color: var(--color-text-primary);
		text-underline-offset: 5px;
	}
}

.recording-list__gear {
	display: flex;
	flex-direction: column;
	font-size: var(--font-size-small);
	line-height: var(--line-height-large);
	gap: var(--spacing-medium);
}

.recording-list__chain,
.recording-list__pedals {
	list-style: none;
}

.recording-list__chain {
	gap: var(--spacing-medium);
}

.recording-list__pedals {
	padding-left: var(--spacing-small);
}

.recording-list__guitar {
	font-weight: var(--font-weight-bold);
}

.recording-list__pedal::before {
	color: var(--color-text-secondary);
	content: "↳ ";
}
