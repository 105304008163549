// Table of contents
.table-of-contents {
	border-bottom: 1px solid var(--color-border);
	max-width: 40rem;
	padding-block-end: var(--spacing-section);

	// Remove default list styles
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}


	// Emphasise top-level headings
	& > ul > li {
		margin-block-start: var(--spacing-medium);

		& > a {
			font-weight: var(--font-weight-bold);
		}
	}

	li {
		margin-block-start: var(--spacing-small);

		&:first-of-type {
			margin-block-start: 0;
		}

		ul {
			border-left: 1px solid var(--color-border);
			margin-block-start: var(--spacing-small);
			padding-inline-start: var(--spacing-small);
		}
	}

	a {
		text-decoration: none;
	}
}

// Styles for sticky TOC
@media screen and (min-width: 65rem) {
	.article-column-wrapper {
		.table-of-contents {
			border-bottom: none;
			// max-width: 30rem;

			& > ul {
				border: 1px solid var(--color-border);
				border-radius: var(--border-radius-medium);
				padding: var(--spacing-large);
			}

			li ul {
				border-left: 0;
				padding-inline-start: var(--spacing-medium);
			}
		}
	}
}
