// This is the inline footnote indicator
sup[id*="fnref"] {
	--indicator-size: 0.875rem;

	align-items: center;
	border: 1px solid var(--color-border);
	border-radius: 50%;
	display: inline-flex;
	height: var(--indicator-size);
	justify-content: center;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: var(--indicator-size);

	@media screen and (min-width: 769px) {
		--indicator-size: 1rem;
	}

	a {
		color: var(--color-text-secondary);
		display: inline-block;
		font-size: var(--font-size-x-small);
		line-height: var(--indicator-size);
		text-align: center;
		text-decoration: none;
		width: var(--indicator-size);

		&:hover {
			background-color: var(--color-background-secondary);
		}
	}
}

// Extra selector needed for specificity
.article {
	.footnotes {
		border-block-start: 1px solid var(--color-border);
		margin: var(--spacing-section) 0 0;
		padding-block-start: var(--spacing-section);

		& > ol {
		  display: flex;
		  flex-direction: column;
		  gap: var(--spacing-medium);
		  list-style: none;
		  margin: 0;
		  padding: 0;
		  padding-inline-start: 0;
		}

		li {
		  align-items: baseline;
		  counter-increment: li;
		  display: flex;
		  font-size: var(--font-size-small);
		  gap: var(--spacing-small);
		  line-height: var(--line-height-large);

		  &::before {
		    --counter-size: 1.5rem;

		    align-items: center;
		    aspect-ratio: 1;
		    border-radius: var(--counter-size);
		    border: 1px solid var(--color-border);
				color: var(--color-text-secondary);
		    content: counter(li);
		    display: flex;
		    flex-shrink: 0;
		    font-size: var(--font-size-x-small);
		    height: var(--counter-size);
		    justify-content: center;
		    width: var(--counter-size);
		  }
		}
	}
}
