.countdowns {
  list-style-type: disc;
	margin: 0;
  margin-inline-start: var(--spacing-medium);
	padding-bottom: var(--spacing-section);
}

.countdowns__countdown + .countdowns__countdown {
  margin-top: var(--spacing-medium);
}
