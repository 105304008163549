.intro {
  padding-block-start: var(--spacing-section-large);
  padding-block-end: var(--spacing-section-large);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
}

.body--article .intro {
  border-bottom: 1px solid var(--color-border);
  margin-block-end: var(--spacing-section);
}

.intro--writing {
  gap: var(--spacing-large);
}

.home {
  .intro {
    border-bottom: none;
    margin-block-start: var(--spacing-section-large);
    padding: 0;
  }

  .intro__heading {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-large);
  }
}

.intro__heading {
  line-height: var(--line-height-large);
}

.intro__subheading {
  color: var(--color-text-secondary);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  max-width: 60ch;
}

.intro__link {
  font-size: var(--font-size-medium);
  word-break: break-word;
}

.intro__metadata {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-small);
}

.intro__date,
.intro__updated-date {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
}
