.hidden {
	display: none !important;
}

.light-only {
  @media (prefers-color-scheme: dark) {
		display: none !important;
	}
}

.dark-only {
	@media (prefers-color-scheme: light) {
		display: none !important;
	}
}
