.film-strip {
	align-items: flex-start;
	display: flex;
	gap: 2rem;
	height: 25rem;
	overflow-x: auto;
	scroll-snap-type: x mandatory;

	& > img {
		display: block;
		max-height: 100%;
		// !important to override `.article figure img` selector
		max-width: calc(100% - 3rem) !important;
		scroll-snap-align: start;
	}
}
