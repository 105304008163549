.article-list {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-x-large);
	list-style-type: none;
	margin: 0;
	padding-bottom: var(--spacing-section);
}

.article-list__article,
.article-list__note {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-small);
	justify-content: flex-end;
}

.article-list__metadata {
	align-items: center;
	color: var(--color-text-secondary);
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	font-size: var(--font-size-small);
	gap: var(--spacing-small);
}

.article-list__article {
	@media screen and (min-width: 769px) {
		align-items: baseline;
		flex-direction: row-reverse;
		gap: var(--spacing-large);

		.article-list__metadata {
			align-items: initial;
			color: var(--color-text-primary);
			flex-direction: column;
			width: 10rem;
		}
	}
}

// This is on the link tag
.article-list__text {
	display: flex;
	flex: 1;
	flex-direction: column;
	font-size: var(--font-size-medium);
	gap: var(--spacing-small);
	line-height: var(--line-height-medium);
	max-width: 75ch;
	text-decoration: none;
	transition: color var(--transition-short) ease-in-out;
}

// Notes also use this class and aren't links
a.article-list__text {
	&:hover,
	&:focus {
		color: var(--color-link-hover-default);
		color: var(--color-text-secondary);
	}
}

.article-list__title {
	font-weight: var(--font-weight-bold);
}

.article-list__excerpt {
	// Account for long URLs in link posts
	word-break: break-word;
}

.article-list__note {
	.article-list__metadata {
		width: 7rem;
	}

	.article-list__text {
		line-height: var(--line-height-large);
	}

	.article-list__excerpt {
		padding-block-start: 0;
		padding-block-end: 0;
	}

	p + p {
		margin-block-start: var(--spacing-small);
	}
}
