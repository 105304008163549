.post-nav {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-medium);
	padding-block-end: var(--spacing-section);

	@media screen and (min-width: 769px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.post-nav__link {
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius-medium);
	color: var(--color-text-primary);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-small);
	padding: var(--spacing-medium);
	position: relative;
	text-decoration: none;
	z-index: 1;

	&::after {
		background-color: var(--color-background-secondary);
		border-radius: var(--border-radius-medium);
		bottom: 0;
		content: '';
		position: absolute;
		transition: var(--transition-medium);
		top: 0;
		width: 0;
		z-index: -1;
	}

	&:hover,
	&:focus {
		// Override global rule
		color: var(--color-text-primary);

		&::after {
			width: 100%;
		}
	}
}

.post-nav__link--newer {
	&::after {
		left: auto;
		right: 0;
	}
}

.post-nav__link--older {
	// Handles case on first article where there is no newer article to fill first column
	grid-column-start: 2;

	&::after {
		left: 0;
		right: auto;
	}
}

.post-nav__label {
	color: var(--color-text-secondary);
	font-size: var(--font-size-small);
	line-height: var(--line-height-small);
}

.post-nav__title {
	font-size: var(--font-size-medium);
	line-height: var(--line-height-medium);
}
