code {
	font-family: var(--font-family-monospace);
	font-variant-ligatures: none;
}

h1 > code,
h2 > code,
h3 > code,
h4 > code,
h5 > code,
li > code,
p code,
.code--inline {
	background-color: var(--color-background-secondary);
	border-radius: var(--border-radius-medium);
	font-size: 0.875em;
	padding-block-start: var(--spacing-xx-small);
	padding-block-end: var(--spacing-xx-small);
	padding-inline-start: var(--spacing-x-small);
	padding-inline-end: var(--spacing-x-small);
}

// Code blocks
// Hooks into classes generated by Rouge syntax highlighter
.highlight {
	pre {
		background-color: var(--color-background-code-block);
		border-radius: var(--border-radius-medium);
		font-size: var(--font-size-small);
		line-height: var(--line-height-large);
		margin: 0;
		max-width: 100%;
		overflow-x: auto;
		padding: var(--spacing-medium);
		position: relative;
	}
}